import { useNavigate } from '@shopify/app-bridge-react';
import { BlockStack, Button, InlineStack, Page, Text } from '@shopify/polaris';
import clsx from 'clsx';
import { PATHS, PRICING_PERIODS } from 'config/constants';
import { useShopInfo } from 'hooks/useShopInfo';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { checkIsVisibleBlackFridayBanner } from 'utils/blackFridayBanner';
import BlackFridayTimer from './BlackFridayTimer';

const GlobalBlackFridayBanner = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  var data = useShopInfo();

  if (!checkIsVisibleBlackFridayBanner(data)) {
    return null;
  }

  return (
    <Page fullWidth>
      <div className="black-friday-wrapper">
        <div className={clsx('box-wrapper', 'divider')}>
          <div>
            <Text variant="headingMd" as="h4" fontWeight="bold">
              <span className="red-color">{t('black_friday_title')}</span>
            </Text>

            <Text variant="headingMd" as="div" fontWeight="medium">
              <span className="white-color">{t('black_friday_exclusive_offer')}</span>
            </Text>
          </div>
        </div>

        <div className={'box-wrapper'} style={{ flex: 1 }}>
          <div style={{ width: '100%' }}>
            <InlineStack gap="300" align="space-between" blockAlign="center">
              <BlockStack gap={'200'}>
                <Text variant="headingLg" as="div" fontWeight="bold">
                  <span className="white-color">
                    {t('black_friday_unlock')}
                    <span className="light-red-color"> {t('black_friday_off')}</span>
                  </span>
                </Text>

                <Text as="div">
                  <span className="white-color">
                    {t('black_friday_all_feature')}
                    <span className="light-red-color">{t('black_friday_free_shopify')} </span>{' '}
                    {t('black_friday_annual_plans')}
                  </span>
                </Text>
              </BlockStack>

              <InlineStack gap="300" blockAlign="center">
                <BlackFridayTimer />

                <div>
                  <Button
                    onClick={() => {
                      // history.push(PATHS.PRICING);
                      navigate(PATHS.PRICING + `?PLAN_PERIOD=${PRICING_PERIODS.YEAR}`);
                    }}
                  >
                    {t('black_friday_get')}
                  </Button>
                </div>
              </InlineStack>
            </InlineStack>
          </div>
        </div>
      </div>
    </Page>
  );
};

export default GlobalBlackFridayBanner;
