import MainLayout from '@/layouts/MainLayout';
import { useClientRouting, useRoutePropagation } from '@shopify/app-bridge-react';
import { Frame } from '@shopify/polaris';
import { PATHS } from 'config/constants';
import React, { Suspense, lazy } from 'react';
import { Route, Switch, withRouter } from 'react-router-dom';

import GlobalBlackFridayBanner from '@/shared/blackFriday/GlobalBlackFridayBanner';
import { useEffect } from 'react';

const Router = ({ location, history }) => {
  
  const StartRouting=()=>{
    try {
      useRoutePropagation(location);
    } catch (error) { }
  
    try {
      useClientRouting(history);
    } catch (error) { }

  }

  useEffect(()=>{
    StartRouting()
  },[])

  return (
    <Suspense fallback={<div></div>}>
    <Frame>
      {/* <TopNav /> */}

      <div className="Main-layout">    
        <GlobalBlackFridayBanner />

        <Switch>
          <MainLayout exact path={PATHS.HOME} component={lazy(() => import('./pages/dashboard/Dashboard'))} />
          <MainLayout exact path={PATHS.PRICING} component={lazy(()=> import('./pages/pricing/Pricing'))} />
          <MainLayout exact path={`${PATHS.HELP}`} component={lazy(()=> import('./pages/help/Help'))} />
          <MainLayout exact path={`${PATHS.HELP}/:tab`} component={lazy(()=> import('./pages/help/Help'))} />
          <MainLayout exact path={PATHS.CAMPAIGNS} component={lazy(()=>import('./pages/campaigns/Campaigns'))} />
          <MainLayout exact path={`${PATHS.CREATE_CAMPAIGN}`} component={lazy(()=>import('./pages/campaignV3/Campaign'))} />
          <MainLayout exact path={`${PATHS.CREATE_CAMPAIGNold}`} component={lazy(()=>import('./pages/campaign/Campaign'))} />
          <MainLayout exact path={PATHS.SELECT_CAMPAIGN} component={lazy(()=>import('./pages/campaignV2/SelectCampaign'))} />
          <MainLayout exact path={`${PATHS.EDIT_CAMPAIGN}/:campaignId`} component={lazy(()=>import('./pages/campaignV3/Campaign'))} />
          <MainLayout exact path={`${PATHS.EDIT_CAMPAIGNold}/:campaignId`} component={lazy(()=>import('./pages/campaign/Campaign'))} />
          <MainLayout exact path={PATHS.SETTING} component={lazy(()=>import('./pages/setting/Setting'))} />
          <MainLayout exact path={`${PATHS.SETTING}/:tab`} component={lazy(()=>import('./pages/setting/Setting'))} />
          <MainLayout exact path={`${PATHS.WIDGETS}`} component={lazy(()=>import('./pages/widgetsV2/index'))} />
          <MainLayout exact path={`${PATHS.COUNTDOWN_SETTINGS}`} component={lazy(()=>import('./pages/countdown-settings/CustomizeCountdown'))} />
          {/* <MainLayout exact path={PATHS.FEATURE_REQUEST} component={FeatureRequestPage} /> */}

          <Route exact path={PATHS.LOGIN} component={lazy(()=>import('./pages/Login'))} />
          <Route component={lazy(()=>import('./pages/NotFound'))} />
        </Switch>
      </div>
    </Frame>
    </Suspense>
  );
};

export default withRouter(Router);
