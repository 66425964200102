import { checkIsStoreNew } from 'utils/checkIsStoreNew';

export const blackFridayDate = new Date('2024-11-29T23:59:59');

export const checkIsVisibleBlackFridayBanner = (data) => {
  let currentDate = new Date();

  if (!checkIsStoreNew({ date: data?.data?.created_at })) {
    return false;
  }

  if (blackFridayDate >= currentDate) {
    return true;
  } else {
    return false;
  }
};
