import { isEmpty } from 'lodash';

export const initialProductCountdownSettings = {
  IsActive: false,
  containsProductTitle: true,
  afterProductTitleText: 'SALE ENDS IN',
  beforeProductTitleText: '',
  footerText: '',
  daysLabel: 'Days',
  hoursLabel: 'Hours',
  minutesLabel: 'Minutes',
  secondsLabel: 'Seconds',
  textColor: '#333333',
  digitColor: '#ffffff',
  digitBackgroundColor: '#212b36',
  progressBarColor: '#5d6dc2',
  progressBarBorderColor: '#808080',
};

const initialProductEmbedSetting = {
  IsActive: false,

  Buy: 'Buy',
  get: 'get',
  off: 'off',
  items: 'items',
  Purchase: 'purchase',
  to: 'to',
  off_by_purchasing: 'off by purchasing',
  Save: 'save',
  bypurchasing: 'by purchasing',
  Buy_at_least: 'Buy at least',

  total: 'Total',
  peritem: 'per item',
  oneach: 'on each',

  // not show in preview
  between: 'between',
  and: 'and',
  discountonyourtotalpurchase: 'Discount on your total purchase',

  header_color: '#000000',
  description_color: '#898f94',
  badge_color: '#FF0101',
  border_color: '#898f94',
  border_color_selected: '#8CB540',
  radio_color: '#8CB540',
};

const initialCartEmbedSetting = {
  IsActive: false,
  totalLabel: 'Total',
  savedLabel: 'Saved',
  totalColor: '#000000',
  savedColor: '#8CB540',
  align: 'default',
  lineThrough: 'true',
  boarderinSavedColor: '#8CB540',
  borderRadius: 8,
  showBorder: true,
};

export function getShopInfoAdaptor(shopInfo, onboard) {
  var onboardingData = false;
  var filteredOnboard = onboard.filter((onboard) => onboard.key == 'onboardingFirstCampaign');
  if (filteredOnboard.length > 0) {
    onboardingData = filteredOnboard[0].value;
  }
  var onboardingActivateCampaignData = false;
  var filteredOnboardingActivateCampaign = onboard.filter(
    (onboard) => onboard.key == 'onboardingActivateCampaign'
  );
  if (filteredOnboardingActivateCampaign.length > 0) {
    onboardingActivateCampaignData = filteredOnboardingActivateCampaign[0].value;
  }
  var onboardingNeedUpgradeCampaignData = false;
  var filteredOnboardingNeedUpgradeCampaign = onboard.filter(
    (onboard) => onboard.key == 'onboardingNeedUpgradeCampaign'
  );
  if (filteredOnboardingNeedUpgradeCampaign.length > 0) {
    onboardingNeedUpgradeCampaignData = filteredOnboardingNeedUpgradeCampaign[0].value;
  }
  var onboardingDismissData = false;
  var filteredOnboardingDismiss = onboard.filter((onboard) => onboard.key == 'onboardingDismiss');
  if (filteredOnboardingDismiss.length > 0) {
    onboardingDismissData = filteredOnboardingDismiss[0].value;
  }
  var onboardingThemeExtensionData = false;
  var filteredOnboardingThemeExtension = onboard.filter(
    (onboard) => onboard.key == 'onboardingThemeExtension'
  );
  if (filteredOnboardingThemeExtension.length > 0) {
    onboardingThemeExtensionData = filteredOnboardingThemeExtension[0].value;
  }
  var onboardingThemeExtension2Data = false;
  var filteredOnboardingThemeExtension2 = onboard.filter(
    (onboard) => onboard.key == 'onboardingThemeExtension2'
  );
  if (filteredOnboardingThemeExtension2.length > 0) {
    onboardingThemeExtension2Data = filteredOnboardingThemeExtension2[0].value;
  }

  // onboardingFinalEnableCheck
  let onboardingFinalEnableCheck = false;
  let filteredOnboardingFinalEnableCheck = onboard.filter(
    (onboard) => onboard.key == 'onboardingFinalEnableCheck'
  );
  if (filteredOnboardingFinalEnableCheck.length > 0) {
    onboardingFinalEnableCheck = filteredOnboardingFinalEnableCheck[0].value;
  }
  console.log('shopinfo', shopInfo);

  return {
    id: shopInfo?.id,
    customer_email: shopInfo.ownerEmail,
    domain: shopInfo.shopifyDomain,
    shop_owner: shopInfo.ownerName,
    ownerEmail: shopInfo.ownerEmail,
    name: shopInfo.name,
    phone: shopInfo.ownerPhoneNumber,
    shopify_domain: shopInfo.shopifyDomain,
    contact_email: shopInfo.ownerEmail,
    myshopify_domain: shopInfo.shopifyDomain,
    money_format: shopInfo.moneyFormat,
    money_with_currency_format: shopInfo.moneyWithCurrencyFormat,
    iana_timezone: shopInfo.ianaTimezone,
    timezone: shopInfo.timezone,
    currency: shopInfo.currency,
    shopify_plan: shopInfo.planName,
    country: shopInfo.country,
    isTest: shopInfo.isTest,
    created_at: shopInfo.createdAt,
    primary_locale: shopInfo.primaryLocale,
    currentPlan: {
      currentPlanId: shopInfo.currentPlan?.id,
      currentPlanPeriod: shopInfo.currentPlanPeriod === 'monthly' ? 0 : 1,
      CurrentPlanUser: '',
      planName: shopInfo.currentPlan?.name,
    },
    // TODO: fake data
    trialEligible: true,
    permissions: {
      can_set_schedule: shopInfo.currentPlan.permissions.includes('campaign_start_schedule'),
      can_set_exclude_product: shopInfo.currentPlan.permissions.includes(
        'campaign_exclude_products'
      ),
      can_set_discount_type: shopInfo.currentPlan.permissions.includes('campaign_start_schedule'),
      can_set_onFly_campaign: true,
      campaign_quantity_and_volume_discount_theme_extension:
        shopInfo.currentPlan.permissions.includes(
          'campaign_quantity_and_volume_discount_theme_extension'
        ),
    },
    canAddCampaign: true,
    onboardingFirstCampaignPassed: onboardingData,
    onboardingActivateCampaignPassed: onboardingActivateCampaignData,
    onboardingNeedUpgradeCampaign: onboardingNeedUpgradeCampaignData,
    onboardingThemeExtension: onboardingThemeExtensionData,
    onboardingThemeExtension2: onboardingThemeExtension2Data,
    onboardingFinalEnableCheck: onboardingFinalEnableCheck,
    onboardingDismiss: onboardingDismissData,
    onboardingFirstCampaign: onboardingData,
    onboardingActivateCampaign: onboardingActivateCampaignData,
    functionOrderNote: shopInfo.functionOrderNote,
    functionProductNote: shopInfo.functionProductNote,
    productCountdownSettings: isEmpty(shopInfo.productCountdownSettings)
      ? initialProductCountdownSettings
      : shopInfo.productCountdownSettings,
    productEmbedSettings: isEmpty(shopInfo.productEmbedSettings)
      ? initialProductEmbedSetting
      : shopInfo.productEmbedSettings,
    cartEmbedSettings: isEmpty(shopInfo.cartEmbedSettings)
      ? initialCartEmbedSetting
      : shopInfo.cartEmbedSettings,
    notification: {
      theme_extension: {
        show: true,
        count: 0,
      },
    },
  };
}
